@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.view-edit-customer-div {

    .top-div {
        margin-bottom: 15px;

        .menu-buttons {
            margin-bottom: 10px;

            button {
                @include normalButton();
                font-size: 12px;
                font-weight: 600;
                padding: 7px 15px;
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                background-color: $primary_color;
                margin: 3px 3px;

                &:hover {
                    background-color: $edit_color;
                }

            }
        }
    }

    form {
        .section-div {
            margin-bottom: 15px;
            background-color: rgb(240, 253, 255);
            padding: 10px;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;

            .sub-title-div {
                color: rgb(65, 65, 65);
                font-size: 18px;
                margin-bottom: 10px;
            }

            .input-section-div {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 0px 10px;

                .input-sub-div {
                    padding: 5px;
                    margin: 5px 0;
                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    border: 1px solid rgb(189, 189, 189);

                    .buttons-div {
                        display: flex;
                        justify-content: flex-end;
                        gap: 5px;

                        button {
                            @include normalButton();
                            font-size: 12px;
                            font-weight: 600;
                            padding: 5px 15px;
                            background-color: rgb(53, 53, 53);
                        }

                        .add {
                            background-color: $green_color;
                        }

                        .delete {
                            background-color: $red_color;
                        }
                    }

                }
            }

            .count-list-div {
                .category-div {
                    margin: 10px 0;

                    .item-div {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        p {
                            width: 100px;
                            font-size: 13px;
                            font-weight: 600;
                        }

                        .icons {
                            span {
                                font-size: 22px;
                            }

                            .add {
                                color: $green_color;
                            }

                            .delete {
                                color: $red_color
                            }
                        }
                    }
                }
            }

        }

        .package-service-div {
            .input-section-div {
                >div {
                    &:nth-child(1) {
                        order: 1;
                    }

                    &:nth-child(2) {
                        order: 2;
                    }

                    &:nth-child(3) {
                        order: 5;
                    }

                    &:nth-child(4) {
                        order: 4;
                    }

                    &:nth-child(5) {
                        order: 3;
                    }

                }
            }
        }

        .button-div {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 15px 25px;
            background-color: white;

            button {
                @include normalButton();
                width: 100%;
                background-color: $edit_color;
                padding: 12px;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }


    @media screen and (max-width :700px) {
        form {
            .section-div {
                .input-section-div {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 0px 10px;
                }
            }

            .package-service-div {
                .input-section-div {
                    >div {
                        &:nth-child(1) {
                            order: 1;
                        }

                        &:nth-child(2) {
                            order: 2;
                        }

                        &:nth-child(3) {
                            order: 3;
                        }

                        &:nth-child(4) {
                            order: 4;
                        }

                        &:nth-child(5) {
                            order: 5;
                        }

                        &:nth-child(6) {
                            order: 6;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width :450px) {
        form {
            .section-div {
                .input-section-div {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 0px 10px;
                }
            }
        }
    }
}