@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.customer-card-details {
    .top-div {
        h3 {
            @include threeDots();

            span {
                color: rgb(65, 65, 65);
            }

        }
    }

    .section-border-div {
        display: flex;
        gap: 25px;
        width: 100%;

        .list-title {
            display: flex;
            align-items: center;
            gap: 10px;

            h4 {
                font-size: 15px;
            }
        }

        .left-div {
            width: 450px;
            min-width: 450px;

            .list-border-div {
                width: 100%;
                margin: 20px 0;
                background-color: rgb(232, 255, 232);
                padding: 15px;

                .list-div {
                    display: grid;
                    grid-template-columns: 180px auto;
                    font-size: 12px;
                    font-weight: 600;
                    gap: 10px;
                    margin: 7px 0;

                    .icons {
                        span {
                            font-size: 18px;
                        }

                        .add {
                            color: $green_color;
                        }

                        .delete {
                            color: $red_color
                        }
                    }
                }

                .credit-amt {
                    color: $red_color;
                }

                .debit-amt {
                    color: $green_color;
                }



                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            }
        }

        .right-div {
            width: calc(100% - 475px);
            display: flex;
            justify-content: center;

            .div-border {
                width: 500px;
                max-width: 100%;
                box-sizing: border-box;
                padding-top: 15px;

                .color-info-div {
                    display: flex;
                    margin-top: 15px;
                    gap: 15px;
                    flex-wrap: wrap;

                    .color-div {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .color-box {
                            width: 10px;
                            height: 10px;
                            border-radius: 100px;
                            -webkit-border-radius: 100px;
                            -moz-border-radius: 100px;
                            -ms-border-radius: 100px;
                            -o-border-radius: 100px;
                            background-color: red;
                        }

                        .primary {
                            background-color: rgb(255, 189, 139)
                        }

                        .secondary {
                            background-color: rgb(151, 153, 255);
                        }

                        .special {
                            background-color: rgb(235, 113, 113);
                        }

                        .amount {
                            background-color: rgb(168, 168, 168);
                        }

                        p {
                            font-size: 11px;
                            font-weight: 600;
                        }
                    }
                }

                .card-image-div {
                    width: 100%;
                    margin-top: 15px;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;
                        margin: 3px 0;

                    }
                }
            }
        }
    }



    @media screen and (max-width:1100px) {
        .section-border-div {
            display: flex;
            flex-wrap: wrap;
            gap: 0px;
            width: 100%;

            .left-div {
                width: 100%;
                min-width: 100%;
                display: flex;
                gap: 25px;
            }

            .right-div {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    @media screen and (max-width:850px) {
        .section-border-div {
            display: flex;
            flex-wrap: wrap;
            gap: 0px;
            width: 100%;

            .left-div {
                width: 100%;
                min-width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 0px;
                justify-content: center;

                .list-border-div {
                    width: 500px;
                    max-width: 100%;
                    margin: 10px 0;

                    .list-div {
                        font-size: 11px;
                        grid-template-columns: 120px auto;
                    }
                }
            }

            .right-div {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}