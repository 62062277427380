@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';





.customer-list-div {

    .search-border-div {
        display: grid;
        grid-template-columns: 280px auto;
        gap: 30px;
        width: 100%;

        .filter-border-div {
            .create-customer {
                margin-bottom: 25px;

                button {
                    width: 100%;
                    @include normalButton();
                    background-color: $green_color;
                    font-size: 13px;
                    display: flex;
                    justify-content: center;
                    font-weight: 600;

                    svg {
                        font-size: 18px;
                        margin: 0;
                        margin-right: 5px;
                    }
                }
            }


            .search-section {
                margin-bottom: 15px;

                h5 {
                    color: rgb(77, 77, 77);
                }
            }


            .button-div {
                display: flex;
                justify-content: flex-end;


                button {
                    @include normalButton();
                    width: 100%;
                    background-color: $primary_color;
                    width: 150px;
                }
            }
        }

        .result-div {
            width: 100%;
            overflow: auto;

            h4 {
                font-size: 16px;
                color: rgb(77, 77, 77);
            }

            .items-div {
                width: 100%;
            }

        }
    }


    @media screen and (max-width:600px) {
        .search-border-div {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;
        }
    }


    tbody {

        td {
            .buttons-div {
                display: flex;
                justify-content: center;
                gap: 10px;
                margin: 10px 0;

                .button-small-icon {
                    @include normalButton();
                    font-size: 16px;
                    padding: 7px 15px 5px 15px;
                    background-color: rgb(53, 53, 53);
                }

                .edit {
                    background-color: $edit_color;
                }

                .delete {
                    background-color: $red_color;
                }

                .add {
                    background-color: rgb(255, 166, 0);
                    color: black;
                }


            }
        }
    }

}