@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';


.service-form-div {
    .border-div {
        display: flex;
        justify-content: center;
        width: 100%;

        .form-border-div {
            width: 600px;
            max-width: 100%;

            .section-one-div {
                .button-div {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    button {
                        @include normalButton();
                        width: 100%;
                        background-color: $edit_color;

                        &:first-child {
                            background-color: $red_color;
                        }
                    }
                }
            }

            .section-two-div {
                .list-border-div {
                    width: 100%;
                    margin: 20px 0;
                    background-color: rgb(232, 255, 232);
                    padding: 15px;

                    .list-title {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        h4 {
                            font-size: 15px;
                        }
                    }

                    .list-div {
                        display: grid;
                        grid-template-columns: 180px auto;
                        font-size: 12px;
                        font-weight: 600;
                        gap: 10px;
                        margin: 7px 0;

                        .icons {
                            span {
                                font-size: 18px;
                            }

                            .add {
                                color: $green_color;
                            }

                            .delete {
                                color: $red_color
                            }
                        }
                    }



                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                }

                .button-div {
                    display: flex;
                    justify-content: flex-end;

                    button {
                        @include normalButton();
                        background-color: black;
                        font-size: 13px;

                    }
                }
            }

            .section-three-div {
                .button-div {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 15px;

                    button {
                        @include normalButton();
                        width: 100%;
                        background-color: $green_color;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}