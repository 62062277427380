@import '../../../assets/scss/model.scss';

.home-page-div {
    .top-bar {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;

        .button-list {
            display: flex;
            gap: 0 10px;

            .div-button {
                display: flex;
                align-items: center;
                gap: 0 5px;
                background-color: rgba(255, 174, 0, 0.363);
                padding: 5px 10px 5px 5px;
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                cursor: pointer;

                .icon-div {
                    width: 30px;
                    height: 30px;
                    background-color: rgb(255, 174, 0);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    -o-border-radius: 100%;
                }

                p {
                    font-weight: 600;
                    font-size: 13px;
                }
            }

            .yellow-list {
                background-color: rgba(255, 174, 0, 0.363);

                .icon-div {
                    background-color: rgb(255, 174, 0);
                }
            }

            .red-list {
                background-color: rgba(226, 48, 48, 0.473);

                .icon-div {
                    background-color: rgb(226, 84, 48);
                }
            }
        }
    }


    .home-border {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 12px;
        box-sizing: border-box;

        .button-div {
            button {
                @include normalButton();
                width: 100%;
                padding-top: 20px;
                padding-bottom: 20px;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width:750px) {
    .home-page-div {
        .home-border {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media screen and (max-width:450px) {
    .home-page-div {
        .home-border {
            grid-template-columns: 1fr;
        }
    }
}